

body {
  background-color: #121212;
  color: #ffffff;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #121212;
}

::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 8px;
}

.app-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 64px);
}

.left-box {
  flex: 0 0 50px;
  background: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
  height:100%;

}

.middle-box {
  flex: 1;
  overflow: auto;
  padding: 16px;
  background: #121212;
  scrollbar-width: thin;
  scrollbar-color: #000 #00;
}

.right-box {
  flex: 0 0 50px;
  background: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-button {
  color: #f2f2f2;
  transition: transform 0.2s ease-in-out;
  transform: scale(1);
}

.is-animating {
  transform: scale(1.2);
}

.header-container {
  color: #ffffff;
  text-align: center;
  margin-bottom: 16px;
}



/* App.css */

/* Add custom styles for the date picker */
.MuiPickersDay-daySelected:not(.MuiPickersDay-dayDisabled) .MuiIconButton-root {
  position: relative;
}

/* Style for the red dot */
.MuiPickersDay-daySelected:not(.MuiPickersDay-dayDisabled) .MuiIconButton-root .red-dot {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: red;
  border-radius: 50%;
  width: 6px;
  height: 6px;
}

/* Hide default styles for the date picker selected day */
.MuiPickersDay-daySelected:not(.MuiPickersDay-dayDisabled) .MuiIconButton-root::after {
  display: none;
}

.MuiContainer-root{
  padding-left: 5px !important;
  padding-right: 0px !important;
}

.wrapper-rainbow {
  width: 100%;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 10px;
  width: 100%;
  /* Use darker colors for the rainbow background gradient */
  background: linear-gradient(
          124deg,
          #740000,
          #660d0d,
          #665c0d,
          #63660d,
          #0d662c,
          #0d6666,
          #0d0d66,
          #660066,
          #660066
  );
  background-size: 1800% 1800%;
  /* Optional: Add a solid background color as a fallback */
  background-color: #333; /* Adjust the brightness or change to a desired color */
  -webkit-animation: rainbow 18s ease infinite;
  -moz-animation: rainbow 18s ease infinite;
  -o-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite;
}

.wrapper-rainbow-dino {
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 10px;
  width: 100%;
  /* Use darker colors for the rainbow background gradient */
  background: linear-gradient(
          124deg,
          #ff0000,
          #9f0000,
          #f6b100,
          #e7f101,
          #0c9a44,
          #17b0b0,
          #10109b,
          #b900b9,
          #770077
  );
  background-size: 600% 600%;
  /* Optional: Add a solid background color as a fallback */
  background-color: #333; /* Adjust the brightness or change to a desired color */
  -webkit-animation: rainbow 18s ease infinite;
  -moz-animation: rainbow 18s ease infinite;
  -o-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite;
}

.wrapper-rainbow-notfullwidth {
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 10px;
  /* Use darker colors for the rainbow background gradient */
  background: linear-gradient(
          124deg,
          #740000,
          #660d0d,
          #665c0d,
          #63660d,
          #0d662c,
          #0d6666,
          #0d0d66,
          #660066,
          #660066
  );
  background-size: 1800% 1800%;
  /* Optional: Add a solid background color as a fallback */
  background-color: #333; /* Adjust the brightness or change to a desired color */
  -webkit-animation: rainbow 18s ease infinite;
  -moz-animation: rainbow 18s ease infinite;
  -o-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite;
}

input{
  font-family: 'Victor Mono' !important;
}

.hanging-image-container {
  position: absolute;
  top: 0;
  left: 250px;
  right: 0 !important;
  margin-top: -3px;  /* Adjust this value based on your design */
  margin-right: 0px;  /* Adjust this value based on your design */
}

.hanging-cat-image {
  right: 0 !important;
  width: 100px;  /* Set the width of your hanging cat image */
  height: auto;  /* Maintain aspect ratio */
  border-radius: 50%;  /* Optional: Apply a border-radius for a circular image */
}



@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-moz-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-o-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}

@media (max-width: 768px) {
  .wrapper-rainbow {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}