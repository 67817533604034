/* Add your custom styles for the header here */

.header-logo {
    height: 40px; /* Adjust the height as per your logo size */
    margin-right: 10px; /* Adjust the margin as needed */
}

.header-title {
    font-family: 'Quicksand', sans-serif; /* Apply the Quicksand font */
    font-size: 24px; /* Adjust the font size as needed */
    color: #ffffff; /* Set the text color to white */
}

.header-appbar {
    background: #000000;
}

